import './Eula.css';
import { VscChevronLeft } from "react-icons/vsc";

function Eula() {
    return (
        <div className="terms__page">

            <section className="stileHero">
                <div className="container">
                    <a href="/">
                        <img className="logo-jhanna" src="/svg/logo-jhanna.svg" />
                    </a>
                </div>

                <div className="container">
                    <div className="">
                        <div className="app-title">
                            <h1>Termos de uso da LowLows</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="divPink">

                <div className="container">
                    <div className="flex-arrownd">
                        <div className="sections-app">
                            <p>
                                A LowLows fornece um serviço personalizado de assinatura que permite aos nossos assinantes acessar conteúdo de entretenimento (“conteúdo da LowLows”) pela Internet, em determinados televisores, computadores e outros aparelhos conectados à Internet (“aparelhos compatíveis com a LowLows”).

                                Estes Termos de uso regulam a sua utilização do serviço LowLows. Usados nestes Termos de uso, os termos “serviço LowLows”, “nosso serviço” ou “o serviço” significam o serviço personalizado fornecido pela LowLows, para buscar e acessar conteúdo da LowLows, incluindo todos os recursos e funcionalidades, recomendações e avaliações, nossos sites e as interfaces do usuário, assim como todo o conteúdo e software associados ao serviço.
                            </p>

                            <div className="article">

                                <h5>1. Assinatura</h5>
                                <p>1.1. Sua assinatura LowLows continuará até que seja cancelada. Para utilizar o serviço LowLows, você precisa ter acesso à Internet e um aparelho compatível com a LowLows, bem como fornecer uma ou mais Formas de Pagamento. “Forma de Pagamento” refere-se a uma forma de pagamento atualizada, válida e aceitável, que poderá ser atualizada periodicamente, e que poderá incluir o pagamento por meio da sua conta com terceiros. A menos que cancele a assinatura antes da data de cobrança, você nos autoriza a cobrar a taxa de assinatura do próximo ciclo de faturamento usando a sua Forma de Pagamento (consulte “Cancelamento” abaixo).</p>

                                <p>1.2. Poderemos oferecer dois (02)  planos de assinatura. Alguns planos de assinatura podem possuir condições e limitações diferentes, que serão reveladas no momento de sua inscrição. Você pode encontrar detalhes específicos a respeito da sua assinatura da LowLows no próprio momento da sua assinatura.</p>

                                <p>Os dados que coletamos dependem do contexto de suas interações com o a JhannaLowCarb, as escolhas que você faz
                                    (incluindo suas configurações de privacidade) e os Serviços que você usa. Os dados que coletamos podem incluir o
                                    seguinte:</p>

                                <h5>Ofertas Promocionais</h5>
                                <p>A LowLows poderá disponibilizar ofertas, planos ou assinaturas promocionais especiais (“Ofertas”). A elegibilidade para as Ofertas é determinada pela LowLows a seu exclusivo critério. A LowLows se reserva o direito de revogar uma Oferta e suspender sua conta se determinarmos que você não é elegível. Moradores de uma mesma residência com uma assinatura LowLows atual ou recente podem não ser elegíveis para Ofertas introdutórias específicas.</p>

                                <h5>Faturamento e Cancelamento</h5>
                                <p> 3.1. Ciclo de faturamento. O preço da assinatura do serviço LowLows e todos os outros encargos incorridos por você em relação ao uso do serviço, tais como impostos e possíveis taxas de transação, serão cobrados, de acordo com a Forma de Pagamento escolhida, na data de pagamento específica indicada na página “Assine”. A duração do ciclo de faturamento dependerá do tipo de assinatura escolhido quando você assinou o serviço. Em alguns casos, a data de pagamento poderá ser alterada, por exemplo, se a Forma de Pagamento não puder ser cobrada, se você alterar o plano de assinatura ou se a sua assinatura paga começar em um dia que não existe em um determinado mês.</p>

                                <p>3.2. Formas de Pagamento. Para utilizar o serviço LowLows, você precisa fornecer uma ou mais Formas de Pagamento. Você nos autoriza a cobrar de diretamente no seu cartão de crédito, para o pagamento do valor da assinatura você concorda que as Formas de Pagamento disponíveis tanto para operações de débito como de crédito poderão ser processadas de ambas as formas. Você continua responsável por todos os valores não pagos. Se um pagamento não for realizado com sucesso, devido à perda de validade, insuficiência de saldo ou qualquer outro motivo, e você não cancelar a sua conta, poderemos suspender o seu acesso ao serviço até que consigamos fazer a cobrança de uma Forma de Pagamento válida. Para algumas Formas de Pagamento, as instituições financeiras e/ou os prestadores de serviço de meios de pagamento poderão cobrar determinadas taxas, como taxas sobre transações financeiras internacionais ou outras taxas relacionadas à Forma de Pagamento. A cobrança de impostos locais pode variar de acordo com a Forma de Pagamento usada. Consulte a instituição financeira da sua Forma de Pagamento para obter mais detalhes.</p>

                                <p>3.3. Atualização das Formas de Pagamento. Você pode atualizar as suas Formas de Pagamento acessando a página “Participe”. Após qualquer atualização, você nos autoriza a continuar a cobrar a(s) respectiva(s) Forma(s) de Pagamento.</p>

                                <p>3.4. Cancelamento. Você pode cancelar sua assinatura LowLows a qualquer momento e você continuará a ter acesso ao serviço LowLows até o fim do período de faturamento. Na extensão permitida pelas leis aplicáveis, os pagamentos não são reembolsáveis e a LowLows não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por conteúdo LowLows não utilizado. Para cancelar, acesse diretamente sua administradora de cartões e siga as instruções de cancelamento. Se você cancelar sua assinatura, sua conta será automaticamente encerrada ao fim do período de cobrança em andamento.</p>

                                <p>3.5. Alterações de preços e planos de assinatura. De tempos em tempos, poderemos alterar nossos planos de assinatura e os preços de nosso serviço. Os valores de assinatura poderão ser revisados periodicamente para melhor adequação ao contínuo aprimoramento de nosso serviço. Nós também poderemos ajustar o valor de sua assinatura anualmente, ou com maior frequência se permitido pela legislação vigente, de acordo com a inflação estabelecida pelo Índice Geral de Preços do Mercado (IGP-M), publicado pela Fundação Getúlio Vargas, ou outro índice equivalente aplicável ao serviço LowLows. Quaisquer alterações de preço ou alterações em seus planos de assinatura serão aplicadas somente 30 dias após o envio da notificação a você.</p>

                                <h5>Serviço LowLows</h5>
                                <p>4.1. O serviço LowLows e todo o conteúdo acessado por intermédio do serviço LowLows destinam-se exclusivamente para uso pessoal e não comercial, não podendo ser compartilhados com pessoas terceiros. Durante sua assinatura LowLows, a LowLows concede a você um direito limitado, não exclusivo e intransferível para acessar o serviço LowLows e o conteúdo da LowLows. Nenhum direito, titularidade ou participação lhe é concedido como multiplicação. Você concorda em não utilizar o serviço em exibições públicas.</p>
                                <p>4.2. Você pode acessar o conteúdo da LowLows, primordialmente no país onde fez sua inscrição e somente nas áreas geográficas onde oferecermos nosso serviço e tivermos licenciado esse conteúdo. O conteúdo que pode estar disponível irá variar segundo a sua localização geográfica e será alterado periodicamente.</p>
                                <p>4.3. O serviço LowLows, inclusive o catálogo de conteúdo, é atualizado regularmente. Além disso, diferentes aspectos do serviço são testados de forma contínua, incluindo nossos sites, as interfaces de usuário, os recursos promocionais e a disponibilidade do conteúdo da LowLows. A qualquer momento, você pode desativar a participação .</p>
                                <p>4.4. Parte do conteúdo da LowLows está disponível para download temporário e visualização offline em alguns aparelhos compatíveis (“Sobre Low Carb”). ESSA DISPONIBILIDADE ESTÁ SUJEITA A ATUALIZAÇÕES E CURIOSIDADES SOBRE O TEMA.</p>
                                <p>4.5. Você concorda em usar o serviço LowLows, incluindo todos os recursos e funcionalidades associadas de acordo com as leis, regras e regulamentos aplicáveis ou outras restrições de uso do serviço ou conteúdo previstas nas mesmas. Você também concorda em não arquivar, reproduzir, distribuir, modificar, exibir, executar, publicar, licenciar ou criar trabalhos derivados, colocar à venda ou utilizar o conteúdo e as informações contidas ou obtidas do serviço LowLows ou por meio deste. Você também concorda em não contornar, remover, alterar, desativar, degradar ou adulterar quaisquer das proteções de conteúdo do serviço LowLows, usar qualquer robô, spider, scraper ou outros meios automatizados para acessar o serviço LowLows, descompilar, executar engenharia reversa ou desmembrar qualquer software ou outros produtos ou processos acessíveis pelo serviço LowLows, inserir qualquer código ou produto ou manipular o conteúdo do serviço LowLows de qualquer forma ou usar métodos de data mining, coleta de dados ou extração de dados. Além disso, você concorda em não fazer upload, publicar, enviar por e-mail, comunicar ou transmitir de qualquer forma qualquer material designado para interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento de telecomunicações associado ao serviço LowLows, incluindo vírus de software, código, arquivos ou programas. A LowLows poderá cancelar ou restringir seu uso do serviço se você violar estes Termos de uso ou se envolver no uso ilegal ou fraudulento do serviço.</p>
                                <p>4.6. O software da LowLows foi desenvolvido pela ou para a LowLows e somente pode ser usado para o acesso autorizado ao conteúdo da LowLows em aparelhos compatíveis com a LowLows. Esse software poderá variar conforme o aparelho e mídia.</p>




                                <h5>Senha à acesso de conta</h5>
                                <p>O assinante que criou a conta LowLows e cuja Forma de Pagamento é cobrada pelo serviço (“Proprietário da Conta”) é responsável por qualquer atividade que ocorra na conta LowLows. Para manter o controle sobre a conta e evitar que qualquer pessoa possa acessá-la (o que incluiria informações dos conteúdo), o Proprietário da Conta deve manter o controle sobre os aparelhos compatíveis com a LowLows utilizados para acessar o serviço e não revelar a ninguém a senha ou os detalhes da Forma de Pagamento associada à conta. Você é responsável por atualizar e manter exatas as informações fornecidas à LowLows relativas à sua conta. A LowLows poderá cancelar ou suspender a sua conta para proteger você, a LowLows ou nossos parceiros contra falsidade ideológica ou outras atividades fraudulentas.</p>

                                <h5>Garantias de isenção de responsabilidades</h5>
                                <p>O SERVIÇO LOWLOWS É FORNECIDO “NO ESTADO EM QUE SE ENCONTRA”, SEM GARANTIAS OU CONDIÇÕES. ESPECIFICAMENTE, O NOSSO SERVIÇO PODE NÃO ESTAR LIVRE DE INTERRUPÇÕES OU ERROS. VOCÊ RENUNCIA A TODAS E QUAISQUER INDENIZAÇÕES POR DANOS INDIRETOS, MORAIS E MATERIAIS CONTRA A LOWLOWS. A LOWLOWS NÃO É RESPONSÁVEL PELA QUALIDADE DE SUA CONEXÃO COM A INTERNET. Estes Termos não limitarão nenhuma garantia e nenhum direito obrigatório não renunciável de proteção do consumidor que se aplicam a você.</p>

                                <h5>Diversos</h5>
                                <p>7.1. Legislação aplicável. Estes Termos de uso devem ser regidos por e interpretados de acordo com a legislação do Brasil.</p>

                                <p>7.2. Materiais não solicitados. A LowLows não aceita materiais ou ideias não solicitados de conteúdo da LowLows e não é responsável pela semelhança de seu conteúdo ou programação em qualquer mídia com materiais ou ideias transmitidos à LowLows.</p>

                                <p>7.3. Subsistência. Se qualquer disposição ou disposições destes Termos de uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade, legalidade e aplicabilidade das demais disposições devem permanecer em pleno vigor.</p>

                                <p>7.4. Alterações dos termos de uso e cessão. A LowLows poderá alterar estes Termos de uso periodicamente. Notificaremos você com pelo menos 30 dias de antecedência antes que as alterações se apliquem a você. A qualquer momento, a LowLows poderá ceder ou transferir o nosso contrato com você, inclusive nossos direitos e obrigações associados. Você concorda em cooperar com a LowLows nessas cessões ou transferências.</p>

                                <p>7.5. Comunicações eletrônicas. Enviaremos informações relacionadas à sua conta (por exemplo, autorizações de pagamento, cobranças, alterações de senha ou Forma de Pagamento, mensagens de confirmação, notificações) somente em formato eletrônico como, por exemplo, por meio de emails para o endereço fornecido durante a inscrição. </p>

                            </div>

                            <p>Última atualização: 2 de novembro de 2021</p>


                        </div>

                    </div>


                </div>

            </section>

            <section className="bluefooter">
                <div className="container">
                    <div className="flex-blue">
                        <div className="buttons-app-footer">
                            <a href="https://appv3.jhanalowcarb.com/" target="_blank">
                                <img src="/svg/acessar_agora.png" />
                            </a>
                        </div>

                        <div className="thumbs">
                            <img src="/img/thumbs-up.png" />
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Eula;
