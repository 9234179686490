import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import './index.css';
import Terms from './Terms';
import Eula from './Eula';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/eula" element={<Eula />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
