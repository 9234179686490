function Home() {
  return (
    <>
      <section className="stileHero">
        <div className="container">
          <a href="/">
            <img className="logo-jhanna" src="/svg/logo-jhanna.svg" />
          </a>
        </div>
        <div className="container">
          <div className="flex-row-header">
            <div className="app-title">
              <h1>DESCOMPLICANDO SUA ROTINA LOW CARB</h1>
              <h2>
                A nova plataforma da Jhana veio para facilitar o seu dia a dia. Pensado com
                carinho, receitas práticas e fáceis para ajudar no seu estilo de
                vida.
              </h2>
            </div>
            <div className="appImage">
              <img src="/img/apps.png" />
            </div>
          </div>
        </div>
      </section>

      <section className="divPink">
        <div className="container downloads">
          <div className="buttons-app">
            <a href="https://appv3.jhanalowcarb.com/" target="_blank">
              <img src="/svg/acessar_agora.png" />
            </a>
          </div>
        </div>

        <div className="container mt-5">
          <div className="flex-arrownd">
            <div className="jhanna-icon">
              <img src="/svg/chefe.png" />
            </div>
            <div className="sections-app">
              <div className="item">
                <div className="itemnumber">
                  <div className="number">1</div>
                </div>
                <div className="description">
                  <h2>
                    Receitas Inéditas com planos mensais ou anuais de
                    assinatura.
                  </h2>
                  <p>
                    Você vai ter 20 receitas todos mês sempre com pelo menos uma
                    receita inédita. Será uma assinatura mensal com preço
                    acessível de R$ 0,43 por dia.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="itemnumber">
                  <div className="number">2</div>
                </div>
                <div className="description">
                  <h2>Tabela nutricional completa.</h2>
                  <p>
                    Tabela nutricional completa de todas as receitas para você
                    saber os carbos líquidos de cada uma delas, e todos os
                    demais nutrientes.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="itemnumber">
                  <div className="number">3</div>
                </div>
                <div className="description">
                  <h2>Busca.</h2>
                  <p>
                    Busca por ingredientes e por receitas e ainda a opção de
                    favoritar a receita preferida.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="itemnumber">
                  <div className="number">4</div>
                </div>
                <div className="description">
                  <h2>
                    Sugestão diária de alimentação e tudo sobre a Low Carb.
                  </h2>
                  <p>
                    Sugestão diária de alimentação para o seu dia a dia. Todo
                    sobre a Low Carb, incluindo a lista de compras, os
                    permitidos e proibidos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bluefooter">
        <div className="container">
          <div className="flex-blue">
            <div className="buttons-app-footer">
              <a href="https://appv3.jhanalowcarb.com/" target="_blank">
                <img src="/svg/acessar_agora.png" />
              </a>
            </div>
            <img className="thumbs" src="/img/thumbs-up.png" />
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="flex-footer">
            <p>Jhanna LowCarb 2022 - Todos os direitos Reservados</p>
            <p>
              <a href="/terms">Termos e Política de Privacidade</a>
            </p>
            <p>
              <a href="/eula">Acordo de licença de usuário (EULA)</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
