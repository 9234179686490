import "./Terms.css";
import { VscChevronLeft } from "react-icons/vsc";

function Terms() {
  return (
    <div className="terms__page">
      <section className="stileHero">
        <div className="container">
          <a href="/">
            <img className="logo-jhanna" src="/svg/logo-jhanna.svg" />
          </a>
        </div>

        <div className="container">
          <div className="">
            <div className="app-title">
              <h1>Termos e Política de Privacidade</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="divPink">
        <div className="container">
          <div className="flex-arrownd">
            <div className="sections-app">
              <div className="article">
                <h5>Quais são as informações que coletamos?</h5>
                <p>
                  A JhannaLowCarb recolhe dados para nos permitir operar os
                  Serviços de forma eficaz e fornecer-lhe as melhores
                  experiências no nosso site e nos nossos Serviços. Você nos
                  fornece alguns desses dados diretamente, como quando você se
                  registra para usar nossos Serviços, inscreve-se em um boletim
                  informativo, responde a uma pesquisa, faz uma consulta através
                  do nosso site, entre em contato conosco para suporte ou entre
                  em contato como um possível cliente, fornecedor, fornecedor ou
                  consultor. Obtemos alguns de seus dados registrando como você
                  interage com nosso site e nossos Serviços, por exemplo, usando
                  tecnologias como cookies. Também obtemos e processamos dados
                  no contexto de fornecimento dos Serviços.
                </p>

                <p>
                  Você tem escolhas sobre os dados que coletamos. Quando você é
                  solicitado a fornecer dados pessoais, você pode recusar. Mas
                  se você optar por não fornecer os dados necessários para nos
                  permitir fornecer os Serviços, talvez não consiga usar todos
                  ou parte desses Serviços.
                </p>

                <p>
                  Os dados que coletamos dependem do contexto de suas interações
                  com o a JhannaLowCarb, as escolhas que você faz (incluindo
                  suas configurações de privacidade) e os Serviços que você usa.
                  Os dados que coletamos podem incluir o seguinte:
                </p>

                <p>
                  Nome e informações de contato . Podemos coletar seu primeiro e
                  último nome, endereço de e-mail, senha, endereço postal,
                  número de telefone, informações da empresa e outros dados de
                  contato semelhantes.
                </p>

                <h5>Informações de pagamento</h5>
                <p>
                  {" "}
                  Quando você faz compras em nosso site, uma janela segura é
                  aberta diretamente ao nosso provedor de pagamento, e qualquer
                  informação de pagamento que você fornecer é enviada
                  diretamente para o provedor de pagamento. Não temos acesso a
                  essa informação.
                </p>

                <h5>Informações do cliente</h5>
                <p>
                  Quando você se inscreve em nossos Serviços a partir de seu
                  website, jogo ou aplicativo, você controla quais dados de
                  eventos são coletados e armazenados em nossos sistemas. Por
                  exemplo, você pode pedir a JhannaLowCarb para registrar dados
                  básicos do usuário (por exemplo, endereço de e-mail ou nome de
                  usuário), identificadores de dispositivo, endereços IP, tipo
                  de evento e código-fonte relacionado. Nesses casos, agimos
                  como um processador de dados, de acordo com suas instruções.
                </p>

                <p>
                  Informações de dispositivo e uso. Podemos coletar dados sobre
                  seu dispositivo e como você e seu dispositivo interagem com a
                  JhannaLowCarb e nossos Serviços. Por exemplo, podemos coletar:
                  Use dados . Podemos coletar dados sobre os recursos que você
                  usa, os Serviços que você compra e as páginas da Web que você
                  visita. Isso também inclui suas interações em nosso site e
                  suas interações conosco por email. Dados de dispositivo,
                  conectividade e configuração. Podemos coletar dados sobre o
                  seu dispositivo e a rede que você usa para se conectar aos
                  nossos Serviços. Isso pode incluir dados sobre o sistema
                  operacional e outros softwares instalados no seu dispositivo,
                  incluindo chaves de produto. Também pode incluir endereço IP,
                  tipo de navegador, sistema operacional e URLs de referência.
                </p>

                <h5>O que usamos as suas informações para?</h5>
                <p>
                  Usamos os dados que coletamos para operar nossos negócios e
                  para fornecer os Serviços para você. Isso inclui usar os dados
                  para melhorar nossos Serviços e personalizar suas
                  experiências. Também podemos usar os dados para nos
                  comunicarmos com você para, entre outras coisas, informá-lo
                  sobre sua conta, fornecer atualizações de segurança e fornecer
                  informações sobre os Serviços. Também podemos usar os dados
                  para gerenciar suas assinaturas de e-mail, melhorar a
                  relevância e segurança de nosso site, responder a consultas de
                  usuários, enviar comunicações de marketing periódicas sobre
                  nossos Serviços e melhorar a relevância de nossa publicidade.
                </p>

                <h5>Como protegemos os seus dados?</h5>
                <p>
                  Implementamos uma variedade de medidas de segurança para
                  manter a segurança de suas informações pessoais quando você
                  insere, envia ou acessa suas informações pessoais. Nós
                  oferecemos o uso de um servidor seguro. Todas as informações
                  confidenciais / de crédito fornecidas são transmitidas por
                  meio da tecnologia Secure Socket Layer (SSL) e criptografadas
                  em nosso banco de dados de provedores de gateway de pagamento
                  apenas para serem acessadas por pessoas autorizadas com
                  direitos especiais de acesso a esses sistemas e obrigadas a
                  manter as informações confidenciais. Após uma transação, suas
                  informações privadas (por exemplo, cartões de crédito, números
                  de seguridade social, finanças) não serão armazenadas em
                  nossos servidores.
                </p>

                <p>
                  Como podemos garantir que nossos sistemas de processamento
                  permaneçam confidenciais, resilientes e disponíveis?
                  Implementamos uma variedade de medidas para garantir que
                  nossos sistemas de processamento permaneçam confidenciais,
                  resilientes e disponíveis. Especificamente, implementamos
                  processos para ajudar a garantir alta disponibilidade,
                  continuidade dos negócios e recuperação imediata de desastres.
                  Comprometemo-nos a manter um forte controle de acesso físico e
                  lógico e realizar testes de penetração regulares para
                  identificar e lidar com possíveis vulnerabilidades.
                </p>

                <h5>Alta disponibilidade</h5>
                <p>
                  Cada parte dos Serviços utiliza servidores redundantes
                  adequadamente provisionados (por exemplo, vários balanceadores
                  de carga, servidores da Web, bancos de dados de réplica) em
                  caso de falha. Colocamos os servidores fora de operação como
                  parte da manutenção regular, sem afetar a disponibilidade.
                </p>

                <h5>Continuidade de negócios</h5>
                <p>
                  {" "}
                  Mantemos backups criptografados de dados a cada hora em várias
                  regiões no Google Cloud Platform. Embora nunca seja esperado,
                  no caso de perda de dados de produção (ou seja, perda de
                  armazenamento de dados primário), restauraremos dados
                  organizacionais desses backups.
                </p>

                <h5>Recuperação de Desastres.</h5>
                <p>
                  No caso de uma indisponibilidade em toda a região, criaremos
                  um ambiente duplicado em outra região do Google Cloud
                  Platform. Nossa equipe de operações tem uma vasta experiência
                  na realização de migrações completas da região.
                </p>

                <h5>Controles de acesso físico. </h5>
                <p>
                  A sentinela está hospedada no Google Cloud Platform. Os data
                  centers do Google apresentam um modelo de segurança em
                  camadas, incluindo salvaguardas abrangentes, como cartões de
                  acesso eletrônico personalizados, alarmes, barreiras de acesso
                  a veículos, cercas de perímetro, detectores de metais e
                  biometria. De acordo com o documento de segurança do Google:
                  "O data center possui detecção de intrusão por feixe de laser.
                  Os datacenters são monitorados 24 horas por dia, 7 dias por
                  semana, por câmeras internas e externas de alta resolução que
                  podem detectar e rastrear intrusos. Registros de acesso,
                  registros de atividades e filmagens de câmeras são revisados
                  ​​caso ocorra um incidente. Os data centers também são
                  rotineiramente patrulhados por seguranças profissionais que
                  passaram por rigorosas verificações e treinamento. "Os
                  funcionários da JhannaLowCarb não têm acesso físico aos
                  datacenters, servidores, equipamentos de rede ou armazenamento
                  do Google.
                </p>

                <p>
                  Controles de Acesso Lógico. A JhannaLowCarb é o administrador
                  designado de sua infraestrutura no Google Cloud Platform, e
                  somente membros autorizados da equipe de operações
                  JhannaLowCarb autorizados têm acesso para configurar a
                  infraestrutura conforme necessário, por trás de uma rede
                  privada virtual autenticada de dois fatores. Chaves privadas
                  específicas são necessárias para servidores individuais, e as
                  chaves são armazenadas em um local seguro e criptografado.
                </p>

                <h5>Teste de penetração</h5>
                <p>
                  {" "}
                  Contratamos uma agência independente terceirizada para
                  realizar testes de penetração de caixa preta anualmente. As
                  informações sobre vulnerabilidades de segurança que são
                  exploradas com êxito por meio de testes de invasão são usadas
                  para definir prioridades de atenuação e correção. Se você é um
                  cliente em um de nossos planos "Enterprise", forneceremos um
                  resumo das descobertas do teste de penetração para você
                  mediante solicitação.
                </p>

                <p>
                  {" "}
                  Detecção e prevenção de intrusões. Padrões incomuns de rede ou
                  comportamento suspeito estão entre as maiores preocupações da
                  JhannaLowCarb em hospedagem e gerenciamento de infraestrutura.
                  Os sistemas de detecção e prevenção de invasões (IDS / IPS) do
                  Google Cloud Platform contam com segurança baseada em
                  assinaturas e baseada em algoritmos para ajudar a identificar
                  padrões de tráfego semelhantes aos métodos de ataque
                  conhecidos. O IDS / IPS envolve o controle rígido do tamanho e
                  da composição da superfície de ataque, empregando controles
                  inteligentes de detecção nos pontos de entrada de dados e
                  desenvolvendo e implantando tecnologias que resolvam
                  automaticamente situações perigosas, bem como impedindo que
                  ameaças conhecidas acessem o sistema no primeiro. Lugar,
                  colocar. Não fornecemos acesso direto à análise forense de
                  eventos de segurança, mas fornecemos acesso a nossas equipes
                  de engenharia e suporte ao cliente durante e após qualquer
                  tempo de inatividade não programado.
                </p>

                <h5> Nós usamos cookies?</h5>
                <p>
                  Sim. Cookies são pequenos arquivos que um site ou seu provedor
                  de serviços transfere para o disco rígido do seu computador
                  através do navegador da Web (se você permitir), permitindo que
                  os sites ou provedores de serviços reconheçam seu navegador e
                  capturem e lembrem certas informações. Você pode optar por
                  desativar os cookies, mas se o fizer, sua capacidade de usar
                  ou acessar certas partes do nosso site pode ser afetada.
                </p>

                <p>
                  Usamos cookies e outros identificadores semelhantes para
                  entender e salvar suas preferências para futuras visitas, para
                  anunciar para você em outros sites e para compilar dados
                  agregados sobre o tráfego do site e a interação no site, para
                  que possamos oferecer melhores experiências e ferramentas para
                  o site no futuro.
                </p>

                <p>
                  Você pode se recusar a aceitar cookies ativando a configuração
                  no seu navegador que permite recusar a configuração de
                  cookies. Você pode encontrar informações sobre navegadores
                  populares e como ajustar suas preferências de cookies.
                </p>
                <p>
                  No entanto, se você optar por desativar os cookies, talvez não
                  consiga acessar determinadas partes do site. Um banner
                  solicitando que você aceite nossa política de cookies será
                  exibido na primeira visita ao nosso site (ou na primeira
                  visita após você excluir os cookies). A menos que você tenha
                  ajustado a configuração do seu navegador para recusar cookies
                  e / ou você não tenha aceitado nossa política de cookies,
                  nosso sistema emitirá cookies quando você acessar o nosso
                  site.
                </p>

                <p>
                  Nossas páginas da Web podem conter imagens eletrônicas
                  conhecidas como web beacons (também chamados de gifs de pixel
                  único) que usamos para ajudar a fornecer cookies em nossos
                  sites e para contar os usuários que visitaram esses sites.
                  Também podemos incluir web beacons em nossas mensagens de
                  e-mail promocionais ou boletins informativos, para determinar
                  se e quando você os abre e age.
                </p>
                <p>
                  Além de colocar web beacons em nossos próprios sites, às vezes
                  trabalhamos com outras empresas para colocar nossos web
                  beacons em seus sites ou em seus anúncios. Isso nos ajuda a
                  desenvolver estatísticas sobre a frequência com que clicar em
                  um anúncio em um site JhannaLowCarb resulta em uma compra ou
                  outra ação no site do anunciante.
                </p>

                <p>
                  Por fim, nossos Serviços podem conter web beacons ou
                  tecnologias semelhantes de provedores de análise de terceiros
                  que nos ajudam a compilar estatísticas agregadas sobre a
                  eficácia de nossas campanhas promocionais ou outras operações.
                  Essas tecnologias permitem que os provedores de análise
                  definam ou leiam seus próprios cookies ou outros
                  identificadores em seu dispositivo, por meio dos quais podem
                  coletar informações sobre suas atividades on-line em
                  aplicativos, sites ou outros produtos. No entanto, proibimos
                  esses provedores de análise de usar web beacons em nossos
                  sites para coletar ou acessar informações que o identifiquem
                  diretamente (como seu nome ou endereço de e-mail).
                </p>

                <h5>Nós revelamos qualquer informação a terceiros?</h5>
                <p>
                  Compartilhamos seus dados pessoais com o seu consentimento ou
                  conforme necessário para fornecer os Serviços a você. Também
                  compartilhamos seus dados com fornecedores que trabalham em
                  nosso nome; quando exigido por lei ou para responder ao
                  processo legal; proteger nossos clientes; proteger vidas;
                  manter a segurança dos nossos serviços; e para proteger nossos
                  direitos ou nossa propriedade.
                </p>
                <p>
                  Compartilhamos seus dados pessoais com o seu consentimento ou
                  conforme necessário para fornecer os Serviços a você. Também
                  compartilhamos dados pessoais com fornecedores ou agentes que
                  trabalham em nosso nome para os fins descritos nesta Política.
                  Por exemplo, as empresas que contratamos para fornecer
                  serviços de hospedagem na nuvem, backups externos e suporte ao
                  cliente podem precisar de acesso a dados pessoais para
                  fornecer essas funções. Em tais casos, essas empresas são
                  obrigadas a cumprir os nossos requisitos de privacidade e
                  segurança de dados e não estão autorizadas a usar dados
                  pessoais que recebam de nós para qualquer outra finalidade.
                  Podemos divulgar seus dados pessoais como parte de uma
                  transação corporativa, como venda corporativa, fusão,
                  reorganização, dissolução ou evento similar.
                </p>

                <p>
                  Por fim, acessaremos, transferiremos, divulgaremos e / ou
                  preservaremos dados pessoais, quando acreditarmos de boa fé
                  que isso é necessário para:
                </p>

                <p>
                  cumprir a lei aplicável ou responder a processos legais
                  válidos, ordens judiciais ou intimações;
                </p>

                <p>
                  responder a solicitações de autoridades públicas ou
                  governamentais, inclusive para fins de segurança nacional ou
                  de aplicação da lei;
                </p>

                <p>
                  proteger os interesses vitais de nossos usuários, clientes ou
                  outros terceiros (incluindo, por exemplo, impedir spam ou
                  tentativas de fraudar usuários de nossos produtos, ou ajudar a
                  evitar a perda de vida ou ferimentos graves de qualquer
                  pessoa);
                </p>

                <p>
                  operar e manter a segurança de nossos Serviços, inclusive para
                  impedir ou interromper um ataque em nossos sistemas ou redes
                  de computadores;
                </p>

                <p>
                  proteger os direitos, interesses ou propriedade da
                  JhannaLowCarb ou de terceiros;
                </p>

                <p>
                  impedir ou investigar possíveis irregularidades relacionadas
                  aos Serviços; ou aplicar nossos Termos de Serviço.
                </p>

                <p>
                  Podemos usar e compartilhar informações não pessoais agregadas
                  com terceiros para fins de marketing, publicidade e análise.
                </p>

                <p>
                  Nós não vendemos ou trocamos suas informações pessoais para
                  terceiros.
                </p>

                <h5>Como acessar e controlar seus dados pessoais</h5>
                <p>
                  Você pode visualizar, acessar, editar, excluir ou solicitar
                  uma cópia de seus dados pessoais para muitos aspectos dos
                  Serviços. Você também pode fazer escolhas sobre a coleção da
                  JhannaLowCarb e o uso de seus dados. Como você pode acessar e
                  controlar seus dados pessoais dependerá de quais Serviços você
                  usa.
                </p>

                <p>
                  Você sempre pode escolher se deseja receber comunicações de
                  marketing de nós. Você também pode optar por não receber
                  comunicações de marketing usando o link de desativação da
                  comunicação ou visitando o painel de privacidade da sua conta.
                </p>

                <h5>Acesso a dados e portabilidade</h5>
                <p>
                  Você pode solicitar uma cópia de seus dados pessoais enviando
                  um email para contato@jhannalowcarb.com.br com os dizeres "Por
                  favor, envie-me uma cópia dos meus dados pessoais" e incluindo
                  um endereço de e-mail. A JhannaLowCarb verificará sua
                  capacidade de acessar esse e-mail e, em seguida, enviará uma
                  exportação digital dos dados que mantemos associados ao seu
                  endereço de e-mail. Faremos esforços razoáveis ​​para
                  responder ao seu pedido no prazo de 14 dias, mas em todos os
                  casos dentro de 30 dias após o recebimento da solicitação.
                  Determinados dados do cliente que você envia diretamente para
                  os Serviços também podem ser exportados diretamente por meio
                  da API da JhannaLowCarb.
                </p>
                <p>
                  Eliminação de dados . Você pode solicitar que JhannaLowCarb
                  exclua seus dados pessoais enviando um para
                  contato@jhannalowcarb.com.br e solicitando "Por favor, apague
                  meus dados pessoais" e incluindo um endereço de e-mail. A
                  JhannaLowCarb verificará sua capacidade de acessar esse e-mail
                  e, em seguida, excluirá os dados pessoais associados ao seu
                  endereço de e-mail. Todos os dados do cliente armazenados em
                  nossos servidores são erradicados após o término do serviço do
                  cliente e a exclusão da conta após um período de espera de 24
                  horas para evitar o cancelamento acidental. Os dados também
                  podem ser excluídos mediante solicitação via API REST e UI da
                  JhannaLowCarb. Você pode remover eventos individuais por meio
                  de "exclusão em massa" de todos os eventos de um problema e
                  pode remover permanentemente os dados relacionados a qualquer
                  tag específica. Você também pode excluir determinados dados,
                  seguindo as instruções aqui. Faremos esforços razoáveis ​​para
                  responder ao seu pedido no prazo de 14 dias, mas em todos os
                  casos dentro de 30 dias após o recebimento da solicitação.
                  Tenha em atenção que retemos os metadados de faturação e
                  utilização de uma empresa ou indivíduo, conforme exigido pelo
                  cumprimento da lei e do regulamento.
                </p>

                <h5>Correção de dados</h5>
                <p>
                  {" "}
                  Você pode modificar seus dados pessoais enviando um e-mail
                  para contato@jhannalowcar.com.br e solicitando "Por favor,
                  atualize meus dados pessoais" e incluindo um endereço de
                  e-mail. A JhannaLowCarb verificará sua capacidade de acessar
                  esse email e, em seguida, atualizará os campos sempre que
                  possível nos Serviços. Em alguns casos, os dados podem ser
                  modificados via API. Faremos esforços razoáveis ​​para
                  responder ao seu pedido no prazo de 14 dias, mas em todos os
                  casos dentro de 30 dias após o recebimento da solicitação.
                </p>

                <h5>Suas preferências de comunicação</h5>
                <p>
                  Você pode escolher se deseja receber comunicações de marketing
                  de nós. Se você receber comunicações de marketing de nós e
                  quiser desativá-las, poderá fazê-lo seguindo as instruções
                  contidas nessa comunicação. Você também pode fazer escolhas
                  sobre o recebimento de comunicações de marketing fazendo login
                  em sua conta e visualizando e gerenciando suas permissões de
                  comunicação no painel de privacidade, onde pode atualizar
                  informações de contato, gerenciar suas preferências de
                  contato, desativar inscrições de e-mail e escolher se para
                  compartilhar suas informações de contato com a JhannaLowCarb e
                  nossos parceiros. Como alternativa, você pode solicitar que
                  retiremos o consentimento para usar seus dados pessoais
                  enviando um email para contato@jhannalowcarb.com.br
                  selecionando "Por favor, retire meu consentimento" e incluindo
                  um endereço de e-mail.
                </p>
                <p>
                  A JhannaLowCarb verificará sua capacidade de acessar esse
                  e-mail e, em seguida, excluirá os dados pessoais associados ao
                  seu endereço de e-mail. Faremos esforços razoáveis ​​para
                  responder ao seu pedido no prazo de 14 dias, mas em todos os
                  casos dentro de 30 dias após o recebimento da solicitação.
                  Observe que essas opções não se aplicam a comunicações
                  obrigatórias que fazem parte dos Serviços ou a pesquisas ou
                  outras comunicações informativas que tenham seu próprio método
                  de cancelamento de assinatura.
                </p>

                <h5>Links de Terceiros</h5>
                <p>
                  Ocasionalmente, a nosso critério, podemos incluir ou oferecer
                  produtos ou serviços de terceiros em nosso site ou através de
                  nossos Serviços. Se você acessar outros sites usando os links
                  fornecidos, os operadores desses sites poderão coletar
                  informações suas que serão usadas por eles de acordo com suas
                  políticas de privacidade. Esses sites de terceiros possuem
                  políticas de privacidade separadas e independentes. Portanto,
                  não nos responsabilizamos pelo conteúdo e atividades desses
                  sites vinculados. No entanto, procuramos proteger a
                  integridade do nosso site e agradecemos qualquer feedback
                  sobre esses sites.
                </p>

                <h5>
                  Onde armazenamos e processamos dados pessoais; Transferências
                  Internacionais
                </h5>
                <p>
                  Os dados pessoais coletados pela JhannaLowCarb podem ser
                  armazenados e processados ​​nos Estados Unidos ou em qualquer
                  outro país onde a JhannaLowCarb ou suas afiliadas,
                  subsidiárias ou prestadores de serviços mantenham instalações.
                  Os locais de armazenamento são escolhidos para operar
                  eficientemente, melhorar o desempenho e criar redundâncias
                  para proteger os dados no caso de uma falha ou outro problema.
                  Tomamos medidas para garantir que os dados que coletamos sejam
                  processados ​​de acordo com as disposições desta Política e
                  com os requisitos da lei aplicável, onde quer que os dados
                  estejam localizados.
                </p>

                <p>
                  Transferimos dados pessoais do Espaço Econômico Europeu e da
                  Suíça para outros países, alguns dos quais não foram
                  determinados pela Comissão Europeia para ter um nível adequado
                  de proteção de dados. Quando nos envolvemos em tais
                  transferências, usamos diversos mecanismos legais, incluindo
                  contratos, para ajudar a garantir que seus direitos e
                  proteções viajem com seus dados. Para saber mais sobre as
                  decisões da Comissão Europeia sobre a adequação da proteção de
                  dados pessoais nos países onde A JhannaLowCarb processa dados
                  pessoais, visite:
                  https://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
                </p>

                <h5>Escudo de privacidade UE-EUA</h5>
                <p>
                  Para informações pessoais que recebemos da União Europeia, a
                  Functional Software, Inc (nosso nome de empresa registrada)
                  certificou sua conformidade com o Escudo de Privacidade
                  UE-EUA, conforme estabelecido pelo Departamento de Comércio
                  dos EUA com relação à coleta, uso e retenção de dados.
                  informações pessoais dos países da UE. Nós certificamos que
                  aderimos aos Princípios do Escudo de Privacidade de
                  Notificação, Escolha, Responsabilidade pela Transferência,
                  Segurança, Integridade de Dados e Limitação de Propósito e
                  Recurso, Execução e Responsabilidade ao processar informações
                  pessoais da UE nos EUA Se houver qualquer conflito entre as
                  políticas nesta Política de Privacidade e os Princípios do
                  Escudo de Privacidade, os Princípios do Escudo de Privacidade
                  deverão prevalecer.
                </p>

                <p>
                  Para acessar a Lista de Proteção de Privacidade e para
                  encontrar detalhes de nossa certificação, visite:
                  www.privacyshield.gov
                </p>

                <p>
                  {" "}
                  A Functional Software, Inc está sujeita aos poderes de
                  investigação e fiscalização da Federal Trade Commission (FTC)
                  em relação à nossa conformidade com o Privacy Shield.
                </p>

                <p>
                  Os residentes da União Europeia que acreditam que suas
                  informações não foram processadas em conformidade com os
                  Princípios do Escudo de Privacidade podem levantar sua queixa
                  de várias maneiras:
                </p>

                <p>
                  (1) Você pode nos contatar diretamente usando os detalhes de
                  contato fornecidos a baixo e responderemos a sua reclamação
                  dentro de 45 dias do recebimento:
                </p>

                <p>Endereço de e-mail: contato@jhannalowcarb.com.br</p>

                <p>
                  (2) Comprometemo-nos ainda a cooperar e a cumprir o painel das
                  Autoridades Europeias de Proteção de Dados (DPAs) como o nosso
                  mecanismo de recurso independente na resolução da sua
                  reclamação do Privacy Shield. Como parte de nossa conformidade
                  com o Regulamento Geral de Proteção de Dados (GDPR), nos
                  registramos na Áustria como nossa Autoridade Supervisora
                  ​​(que atua como nossa "DPA líder" para todos os assuntos
                  relacionados à conformidade com o GDPR.
                </p>

                <p>
                  Se você não estiver satisfeito com a resposta recebida, ou se
                  sua reclamação continuar sem solução, você pode entrar em
                  contato com o seu DPA local (detalhes de contato disponíveis
                  aqui ) e eles investigarão sua reclamação gratuitamente.
                </p>

                <p>
                  (3) Além disso, sua reclamação pode ser encaminhada ao
                  Departamento de Comércio dos EUA ou ao FTC para investigação
                  adicional;
                </p>

                <p>
                  (4) Se a sua reclamação não for resolvida através dos métodos
                  acima, um processo de arbitragem vinculativo pode estar
                  disponível sob a Proteção da Privacidade em determinadas
                  circunstâncias. Para saber mais sobre o esquema de arbitragem
                  obrigatória do Privacy Shield, consulte este artigo.
                </p>
                <p>
                  Se tivermos recebido suas informações pessoais nos EUA sob o
                  Escudo de Privacidade e, posteriormente, transferirmos essas
                  informações para um agente ou prestador de serviços
                  terceirizados para processamento, seremos responsáveis ​​se
                  nosso agente terceirizado ou provedor de serviços falhar em
                  processar suas informações pessoais. conformidade com os
                  Princípios do Escudo de Privacidade e isso faz com que você
                  seja prejudicado.
                </p>

                <h5>Retenção de dados</h5>
                <p>
                  {" "}
                  Podemos manter suas informações pessoais desde que você
                  continue a usar os Serviços, tenha uma conta conosco ou pelo
                  tempo necessário para cumprir as finalidades descritas na
                  política. Você pode pedir para encerrar sua conta entrando em
                  contato conosco nos detalhes acima e excluiremos suas
                  informações pessoais mediante solicitação.
                </p>

                <p>
                  Podemos, no entanto, reter informações pessoais por um período
                  adicional conforme permitido ou exigido pelas leis aplicáveis,
                  por motivos legais, fiscais ou regulamentares, ou para fins
                  comerciais legítimos e legais.
                </p>

                <p>
                  Nós reteremos seus dados pessoais pelo tempo que for
                  necessário para fornecer os Serviços para você, ou para outros
                  fins essenciais, como cumprir nossas obrigações legais,
                  resolver disputas e fazer cumprir nossos contratos. Como essas
                  necessidades podem variar para diferentes tipos de dados no
                  contexto dos diferentes Serviços que fornecemos, os períodos
                  de retenção reais podem variar significativamente. Os
                  critérios que usamos para determinar os períodos de retenção
                  incluem:
                </p>

                <p>
                  {" "}
                  *Por quanto tempo os dados pessoais são necessários para
                  fornecer os Serviços e / ou operar nossos negócios? *Isso
                  inclui coisas como manter e melhorar o desempenho dos
                  Serviços, manter nossos sistemas seguros e manter registros
                  comerciais e financeiros apropriados. Essa é a regra geral que
                  estabelece a linha de base para a maioria dos períodos de
                  retenção de dados.
                </p>

                <p>
                  {" "}
                  *Existe um controle automatizado, como no seu painel de
                  controle de privacidade, que permite acessar e excluir os
                  dados pessoais a qualquer momento? *Se não houver, um tempo
                  reduzido de retenção de dados geralmente será adotado.
                </p>

                <p>
                  *Os dados pessoais são de um tipo sensível? *Nesse caso, um
                  tempo de retenção reduzido geralmente seria apropriado.
                </p>

                <p>
                  {" "}
                  *O usuário forneceu consentimento para um período de retenção
                  mais longo? *Se assim for, manteremos os dados de acordo com o
                  seu consentimento.
                </p>

                <p>
                  *A JhannaLowCarb está sujeita a uma obrigação legal,
                  contratual ou similar de reter os dados? *Os exemplos podem
                  incluir leis obrigatórias de retenção de dados na jurisdição
                  aplicável, ordens do governo para preservar dados relevantes
                  para uma investigação ou dados que devem ser retidos para fins
                  de litígio.
                </p>

                <p>
                  Conformidade com a Lei de Proteção à Privacidade Online da
                  Califórnia Como valorizamos sua privacidade, tomamos as
                  precauções necessárias para estar em conformidade com o
                  California Online Privacy Protection Act.
                </p>

                <p>
                  Como parte do California Online Privacy Protection Act, todos
                  os usuários do nosso site podem fazer alterações em suas
                  informações a qualquer momento, acessando o painel de controle
                  e acessando a página "Editar perfil".
                </p>

                <h5>Alterações à nossa política de privacidade</h5>
                <p>
                  Atualizaremos esta declaração de privacidade quando necessário
                  para refletir o feedback do cliente e as alterações em nossos
                  Serviços. Quando postarmos alterações nesta declaração,
                  revisaremos a data da última atualização na parte superior da
                  declaração. Se houver alterações substanciais na declaração ou
                  em como a JhannaLowCarb utilizará seus dados pessoais,
                  notificaremos você publicando um aviso sobre essas alterações
                  antes que elas entrem em vigor ou enviando diretamente uma
                  notificação. Encorajamos você a revisar periodicamente esta
                  declaração de privacidade para saber como o JhannaLowCarb está
                  protegendo suas informações.
                </p>

                <h5>Como entrar em contato conosco</h5>
                <p>
                  Se você tiver alguma dúvida técnica ou de suporte, entre em
                  contato conosco por meio do nosso site
                </p>

                <p>
                  Se você tiver um problema de privacidade, reclamação ou uma
                  pergunta para o Responsável pela proteção de dados da
                  JhannaLowCarb, entre em contato conosco enviando um email
                  contato@jhannalowcarb.com.br . Responderemos a perguntas ou
                  dúvidas no prazo de 30 dias.
                </p>

                <p>
                  Salvo disposição em contrário, a Functional Software, Inc. é
                  uma controladora de dados para dados pessoais que coletamos
                  através dos Serviços sujeitos a esta declaração.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bluefooter">
        <div className="container">
          <div className="flex-blue">
            <div className="buttons-app-footer">
              <a href="https://appv3.jhanalowcarb.com/" target="_blank">
                <img src="/svg/acessar_agora.png" />
              </a>
            </div>

            <div className="thumbs">
              <img src="/img/thumbs-up.png" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Terms;
